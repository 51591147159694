// Importing styles
import "./accessibility.scss";

// Importing modules
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { SharedText } from "../../data/main.ts";

const Accessibility = () => {
  const currentDomain = window.location.hostname;

  return (
    <div className="about page">
      <section role="banner" className="search__banner">
        <Container fluid="lg">
          <Row>
            <h1>{SharedText.AccessibilityTitle}</h1>
          </Row>
        </Container>
      </section>

      <section>
        <Container fluid="lg">
          <div className="about-wrapper">
            <article className="article">
              <header className="about__header">
                <h2 className="about-title">{SharedText.AccessibilityTitle}</h2>
              </header>
              <section className="about__section">
                <h3>Deklaracja dostępności</h3>
                <p>
                  Strona internetowa {currentDomain} zobowiązuje się
                  zapewnić dostępność swojej strony internetowej zgodnie z
                  ustawą z dnia 4 kwietnia 2019 r. o dostępności cyfrowej stron
                  internetowych i aplikacji mobilnych podmiotów publicznych.
                  Niniejsza deklaracja dostępności dotyczy strony internetowej&nbsp;
                  {currentDomain}.
                </p>

                <h3>Status pod względem zgodności</h3>
                <p>
                  Strona internetowa jest częściowo zgodna z ustawą o
                  dostępności cyfrowej z powodu niezgodności lub wyłączeń
                  wymienionych poniżej.
                </p>

                <h3>Treści niedostępne:</h3>
                <ul>
                  <li>
                    Niektóre pliki do pobrania mogą nie być w pełni dostępne
                    cyfrowo (np. skany dokumentów).
                  </li>
                  <li>
                    Filmy wideo zamieszczone na stronie mogą nie posiadać
                    napisów dla osób niesłyszących.
                  </li>
                  <li>
                    Elementy graficzne i zdjęcia mogą nie zawierać opisów
                    alternatywnych (alt).
                  </li>
                </ul>

                <h3>Wyłączenia:</h3>
                <ul>
                  <li>
                    Pliki opublikowane przed 23 września 2018 r. są wyłączone z
                    obowiązku zapewnienia dostępności.
                  </li>
                  <li>
                    Treści będące w posiadaniu innych podmiotów, które nie
                    zostały przez nas opracowane ani zamówione.
                  </li>
                </ul>

                <h3>Przygotowanie deklaracji dostępności</h3>
                <p>
                  Niniejszą deklarację sporządzono dnia: 02.10.2024
                </p>
                <p>
                  Deklarację sporządzono na podstawie samooceny przeprowadzonej
                  przez podmiot.
                </p>

                <h3>Informacje zwrotne i dane kontaktowe</h3>
                <p>
                  W przypadku problemów z dostępnością strony internetowej
                  prosimy o kontakt:
                </p>
                <ul>
                  <li>
                    E-mail:{" "}
                    <a href="mailto:bialystokfederacja@gmail.com">
                      bialystokfederacja@gmail.com
                    </a>
                  </li>
                  <li>Telefon: 609 606 669</li>
                </ul>

                <h3>Każdy ma prawo:</h3>
                <ul>
                  <li>
                    zgłosić uwagi dotyczące dostępności cyfrowej strony lub jej
                    elementu,
                  </li>
                  <li>
                    złożyć wniosek o udostępnienie informacji niedostępnej,
                  </li>
                  <li>zażądać zapewnienia dostępności.</li>
                </ul>

                <h3>Procedura odwoławcza</h3>
                <p>
                  W przypadku, gdy podmiot odmówi realizacji żądania zapewnienia
                  dostępności lub alternatywnego dostępu do informacji, można
                  złożyć skargę na takie działanie.
                </p>
                <p>
                  Adres do korespondencji: Federacja Organizacji Pozarządowych
                  Miasta Białystok, ul. Proletariacka 21, 15-449 Białystok,
                  Polska
                </p>
                <p>
                  Można również skontaktować się z Rzecznikiem Praw
                  Obywatelskich (
                  <a href="https://www.rpo.gov.pl">www.rpo.gov.pl</a>).
                </p>

                <h3>Informacje dodatkowe</h3>
                <p>
                  Data ostatniej aktualizacji deklaracji: 12.12.2024
                </p>
                <p>
                  Na stronie internetowej można korzystać ze standardowych
                  skrótów klawiaturowych.
                </p>
                <p>
                  Strona jest dostosowana do wyświetlania na urządzeniach
                  mobilnych.
                </p>
              </section>
            </article>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default Accessibility;
