export enum SharedText {
  HomeTitle = 'KOSZYK USŁUG <br/>SPOŁECZNYCH',
  SearchTitle = 'Koszyk Usług Społecznych',
  DetailsTitle = 'Informacje o usłudze',
  AboutTitle = 'Skoordynowane usługi społeczne',
  AccessibilityTitle = 'Deklaracja dostępności',
  Error404 = 'Strona nie znaleziona',
  HeaderLogoAlt = 'Logo - przekierowanie na stronę główną',
  FooterLogoKomitetAlt ='Komitet do spraw pozytku publicznego - logo',
  FooterLogoNIWAlt = 'Narodowy instytut wolnosci - logo',
  FooterLogoInfoAlt = 'Sfinansowano ze srodkow narodowego instytutu wolnosci - info',
  FooterLogoNowefioAlt = 'Rzadowy program funduszy inicjatyw obywatelskich - logo',
  FooterLogoRops = 'Regionalny Ośrodek Polityki Społecznej w Białymstoku - logo',
  FooterLogoWojewodztwo = 'Województwo Podlaskie - logo'
}

export enum FormText {
  SearchPlaceholder = 'JAKIEJ USŁUGI SZUKASZ?',
  SelectPlaceholder = 'Wybierz...',
  LabelCounty = 'Powiat',
  LabelCommunity = 'Gmina',
  LabelRanges = 'Zakres usług',
  LabelGroup = 'Grupa docelowa',
  LabelPayment = 'Typ usługi - Koszt',
  LabelLocalization = 'Typ usługi - Lokalizacja'
}

export enum ButtonText {
  SubmitButton = 'Szukaj',
  ClickHereButton = 'ZOBACZ WIĘCEJ'
}

export enum TagsTypes {
  PAID = 'PŁATNA',
  FREE = 'DARMOWA',
  REMOTE = 'ZDALNA',
  COMMUTING = 'Z DOJAZDEM',
  LOCAL  = 'LOKALNA'
}

export enum ContactTitles {
  ContactTitle = 'KONTAKT',
  PhoneTitle = 'TELEFON',
  EmailTitle = 'EMAIL',
  WebsiteTitle = 'WWW',
  DetailTitle = 'Szczegóły usługi'
}

export enum DetailsTitles {
  RangesTitle = 'ZAKRES USŁUG',
  AdditionalConditionsTitle = 'DODATKOWE WARUNKI',
  GroupTitle = 'GRUPA DOCELOWA',
  OpeningHoursTitle= 'GODZINY OTWARCIA',
  DeliveryAddressTitle ='ADRES DOSTAWCY USŁUGI',
  RealizationAddressTitle ='ADRES REALIZACJI USŁUGI',
  RemoteAddressTitle = 'ADRES USŁUGI ZDALNEJ'
}

export enum ArticleText {
  article1Text = 'To konsultacje, poradnictwo, warsztaty, terapia, diagnoza, zajęcia sportowe i kulturalne, asystentura, praca socjalna, szkolenia, opieka dzienna, wypożyczanie sprzętu rehabilitacyjnego, doradztwo, gimnastyka, rehabilitacja, grupa wsparcia, pedagogizacja, wsparcie prawne, psychologiczne oraz specjalistyczne usługi opiekuńcze. To takze treningi umiejętności i zajęcia artystyczne. Znajdź potrzebną Ci pomoc i ciesz się wsparciem, które oferujemy!',
  article2Text = 'To baza danych o usługach społecznych w województwie podlaskim, dostępnych dla osób, grup i społeczności w tym regionie. Jest to również wyszukiwarka usług, która oferuje różnorodne filtry dostępności, takie jak grupy odbiorców, cel usługi, miejsce/teren świadczenia usługi, kryteria dostępności, koszty-odpłatności, standardy oraz pakiety usług. Dzięki temu można łatwo znaleźć usługi dedykowane osobom z niepełnosprawnościami, dzieciom, osobom starszym itp.',
  article3Text = 'Federacja Organizacji Pozarządowych Miasta Białystok, jako autor aplikacji i inicjator projektu Skoordynowane Usługi Społeczne, finansowanego ze środków programu NOWE FIO, jest pośrednikiem pomiędzy dostawcami usług a osobami oraz podmiotami poszukującymi usług społecznych. Nasza organizacja ma na celu zbieranie i udostępnianie danych o usługach poprzez aplikację, aby ułatwić ich dostępność. KOSZYK USŁUG WOJEWÓDZTWA PODLASKIEGO został stworzony w ramach tego projektu. ',
  article2Title = 'CZYM JEST KOSZYK USŁUG SPOŁECZNYCH?',
  article3Title ='DLACZEGO FEDERACJA?',
  article1Title ='USŁUGI SPOŁECZNE - CZYM SĄ?',
}